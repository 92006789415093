import React, {Component} from 'react';


class Experience extends Component {
    render() {
        const detailsArray = this.props.item.details.split('\n');
		return (
			<div className="item">
                <div className="meta">
                    <div className="upper-row">
                        <h3 className="job-title">{this.props.item.title}</h3>
                        <div className="time">{this.props.item.period}</div>
                    </div>
                    <div className="company"><a href={this.props.item.companyURL} target="_blank">{this.props.item.company}</a></div>
                </div>
                <div className="details">
                    {detailsArray.map((value,i)=>{
                        return <p key={i}>{value}</p>
                    })}
                </div>
            </div>
		);
	}
}

export default Experience;