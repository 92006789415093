import React, { Component } from 'react';
import './App.css';
import './css/bootstrap.css'
import './css/styles-6.css'
// import resume from './resume.json';
import Experience from './Experience';
import Project from './Project';
import Skill from './Skill';
import Education from './Education';
import Language from './Language';
import axios from 'axios';
import Loader from './loader/index';
import Certification from './Certification';
import Achievements from './Achievements';
import ReactGA from 'react-ga4';



class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resume: {},
            updated: false
        }


        
    }

    componentDidMount() {
        this.fetchResume();
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('G-57D9Z6XPZW');
        ReactGA.send({ hitType: "pageview", page: "/" });

    }
    
    

    async fetchResume(){
        //Get Resume from Firebase
        var MY_RESUME = 'https://resume-akreact.firebaseio.com/.json';
        // var MY_RESUME = 'https://crud-sample-166d4.firebaseio.com/.json';
        // var MY_RESUME = './resume.json';
        await axios.get(MY_RESUME).then((response) => {
            this.setState({resume: response.data, updated: true});
            this.renderEducations()
        })
        .catch((response) => {
            console.log(response);
        })

        // console.log(this.state.resume);
    }
    
  renderExperiences() {
    let resultsArray = [];
    if(this.state.updated){
        this.state.resume.experiences.forEach((item, i) => {
            resultsArray.push(<Experience item={item} key={i} />);
        });
        return resultsArray;
    }
  }

  renderCertification() {
    let resultsArray = [];
    if(this.state.updated){
        this.state.resume.certification.forEach((item, i) => {
            resultsArray.push(<Certification item={item} key={i} />);
        });
        return resultsArray;
    }
  }

  renderAchievements() {
    let resultsArray = [];
    if(this.state.updated){
        this.state.resume.achievements.forEach((item, i) => {
            resultsArray.push(<Achievements item={item} key={i} />);
        });
        return resultsArray;
    }
  }

  renderProjects() {
    let resultsArray = [];
    if(this.state.updated){
        this.state.resume.projects.forEach((item, i) => {
            resultsArray.push(<Project item={item} key={i} />);
        });
        return resultsArray;
    }
  }

  renderSkills() {
    let resultsArray = [];
    if(this.state.updated){
        this.state.resume.skills.forEach((item, i) => {
            resultsArray.push(<Skill item={item} key={i} />);
        });
        return resultsArray;
    }
  }

  renderEducations() {
    let resultsArray = [];
    if(this.state.updated){
        this.state.resume.education.forEach((item, i) => {
            resultsArray.push(<Education item={item} key={i} />);
        });
        return resultsArray;
    }
    
  }

  renderLanguages() {
    let resultsArray = [];
    if(this.state.updated){
        this.state.resume.languages.forEach((item, i) => {
            resultsArray.push(<Language item={item} key={i} />);
        });
        return resultsArray;
    }
  }

  renderInterests() {
    let resultsArray = [];
    if(this.state.updated){
        this.state.resume.interests.forEach((item, i) => {
            resultsArray.push(<li key={i}>{item.name}</li>);
        });
        return resultsArray;
    }
  }

  render() {
    return (
        <div className="wrapper">
            {
                (!this.state.updated) &&
                <Loader/>
            }
            <div className="sidebar-wrapper">
                <div className="profile-container">
                    <img className="profile img-circle" src={this.state.resume.image} alt="" />
                    <h1 className="name">{this.state.resume.name}</h1>
                    <h3 className="tagline">Senior Principal Software Engineer</h3>
                </div>
                
                <div className="contact-container container-block">
                    <ul className="list-unstyled contact-list">
                        <li className="email"><i className="fa fa-envelope"></i><a href={"mailto:" + this.state.resume.email}>{this.state.resume.email}</a></li>
                        {/* <li className="phone"><i className="fa fa-phone"></i><a href={"tel:" + phone}>{phone}</a></li> */}
                        <li className="website"><i className="fa fa-globe"></i><a href={"https://"+this.state.resume.website} target="_blank">{this.state.resume.website}</a></li>
                        <li className="website"><i className="fa fa-globe"></i><a href={"https://"+this.state.resume.website2} target="_blank">{this.state.resume.website2}</a></li>
                        <li className="twitter"><i className="fa fa-twitter"></i><a href={this.state.resume.twitterURL} target="_blank">{this.state.resume.twitter}</a></li>
                        <li className="resumepdf"><i className="fa fa-file-pdf-o"></i><a href={this.state.resume.pdf} target="_blank">Download Resume (PDF)</a></li>
                        <li className="github"><i className="fa fa-github"></i><a href={this.state.resume.github} target="_blank">GitHub</a></li>
                        <li className="stackoverflow"><i className="fa fa-stack-overflow"></i><a href={this.state.resume.stackoverflow} target="_blank">Stackoverflow</a></li>
                        <li className="linkedin"><i className="fa fa-linkedin"></i><a href={this.state.resume.linkedin} target="_blank">LinkedIn</a></li>
                    </ul>
                </div>
                <div className="education-container container-block">
                    <h2 className="container-block-title">Education</h2>
                    {this.renderEducations()}
                </div>
                
                <div className="languages-container container-block">
                    <h2 className="container-block-title">Languages</h2>
                    <ul className="list-unstyled interests-list">
                        {this.renderLanguages()}
                    </ul>
                </div>
                
                <div className="interests-container container-block">
                    <h2 className="container-block-title">Interests</h2>
                    <ul className="list-unstyled interests-list">
                        {this.renderInterests()}
                    </ul>
                </div>
                
            </div>
            
            <div className="main-wrapper">
                
                <section className="section summary-section">
                    <h2 className="section-title"><i className="fa fa-user"></i>Objective</h2>
                    <div className="summary">
                        <p>To give my best in my professional pursuit for overall benefit 
                            and growth of the company that I serve by facing the 
                            challenges. I will show my caliber and gain some experience</p>
                    </div>
                </section>
                
                <section className="section experiences-section">
                    <h2 className="section-title"><i className="fa fa-briefcase"></i>Experience</h2>

                    {this.renderExperiences()}
                    
                </section>

                <section className="skills-section section">
                    <h2 className="section-title"><i className="fa fa-rocket"></i>Skills &amp; Proficiency</h2>
                    <div className="skillset"> 
                        {this.renderSkills()}
                        
                    </div>  
                </section>

                <section className="section experiences-section">
                    <h2 className="section-title"><i className="fa fa-certificate"></i>Certifications</h2>

                    {this.renderCertification()}
                    
                </section>
                
                <section className="section projects-section">
                    <h2 className="section-title"><i className="fa fa-archive"></i>Side Projects</h2>
                    <div className="intro">
                        <p>
                            Below are side projects and open source library I created in my free time, from initial conception to development.
                        </p>
                    </div>

                    {this.renderProjects()}

                </section>

                <section className="section experiences-section">
                    <h2 className="section-title"><i className="fa fa-trophy"></i>Achievements</h2>

                    {this.renderAchievements()}
                    
                </section>
                
                
                
            </div>
        </div>
      
    );
  }
}

export default App;
