import React, {Component} from 'react';
/*global $*/

class Project extends Component {

	componentDidMount(){
		$('.level-bar-inner').css('width', '0');

		$(document).ready(function(){
			$('.level-bar-inner').each(function() {
			
				var itemWidth = $(this).data('level');
				
				$(this).animate({
					width: itemWidth
				}, 800);
				
			});
		});
	}

	render() {
		return (
			<div className="item">
          		<span className="project-title"><a href={this.props.item.url} target="_blank">{this.props.item.name}</a></span> - <span className="project-tagline">{this.props.item.detail}</span>
      		</div>
		);
	}
}

export default Project;