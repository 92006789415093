import React, { Component } from "react";

class Certification extends Component {
  render() {
    return (
      <div className="item">
        <div className="meta">
          <div className="upper-row">
            <h3 className="job-title">{this.props.item.title}</h3>
            <div className="time">{this.props.item.on}</div>
          </div>
          <div className="company">
            <a href={this.props.item.url} target="_blank">{this.props.item.no}</a>
          </div>
        </div>
        <div className="details">
          <p>{this.props.item.from}</p>
        </div>
      </div>
    );
  }
}

export default Certification;
