import React, { Component } from "react";

class Achievements extends Component {
  render() {
    const descriptionArray = this.props.item.description.split('\n');
    return (
      <div className="item">
        <div className="meta">
          <div className="upper-row">
            <h3 className="job-title">{this.props.item.title}</h3>
            <div className="time">{this.props.item.on}</div>
          </div>
          <div className="company">
            {this.props.item.from}
          </div>
        </div>
        <div className="details">
          {descriptionArray.map((value,i)=>{
            return <p key={i}>{value}</p>
          })}
        </div>
      </div>
    );
  }
}

export default Achievements;
